'use client';

import React from 'react';
import { Button } from '@gravity/button';
import { Text } from '@gravity/text';
import { useTranslations } from 'next-intl';
import { ControlledFormField } from '@monorepo/tailwind-ui/src/components/ControlledFormField';
import { Form } from '@monorepo/tailwind-ui/src/components/Form';
import { cn } from '@monorepo/tailwind-ui/utils';
import { FixedWrapper } from '@src/components/FixedWrapper';
import { useSendTaxIdForm } from './useSendTaxIdForm';
export default function TaxIdForm() {
  const t = useTranslations('signInPage');
  const {
    form,
    onSubmit,
    isLoading,
    taxIdValid
  } = useSendTaxIdForm();
  return <Form {...form} data-sentry-element="Form" data-sentry-component="TaxIdForm" data-sentry-source-file="index.tsx">
      <form onSubmit={onSubmit} className="flex h-full flex-col justify-between md:gap-6">
        <div className="flex flex-col gap-4">
          <ControlledFormField id="taxId" control={form.control} name="taxId" type="string" variant="primary" inputMode="numeric" mask="cpf" placeholder={t('enterTaxId')} data-sentry-element="ControlledFormField" data-sentry-source-file="index.tsx" />

          <Text className="text-gray-600" data-sentry-element="Text" data-sentry-source-file="index.tsx">{t('rfDescription')}</Text>
        </div>

        <FixedWrapper data-sentry-element="FixedWrapper" data-sentry-source-file="index.tsx">
          <Button className="gap-6" fullWidth type="submit" disabled={!taxIdValid} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {t('continue')}
          </Button>
        </FixedWrapper>
        <div className={cn('absolute inset-0 mb-11 hidden h-dvh w-full bg-white opacity-40', isLoading && 'block')} />
      </form>
    </Form>;
}